// export const merchantId = 'b5a84f164746cfd448b144f543e22808';

export const merchantId = 'b0e872cf32bf42febb53b33e1f7955dd';

export const oneImage = '/sol-api/image/v2/oneImage'; // 上传图片
export const oneVideo = '/sol-api/apply/v2/oneImage'; // 上传视频
export const brand = '/sol-api/system/brand'; // 获取公司信息
export const getUserInfo = '/sol-api/user/getUserInfo'; // 获取用户信息
export const queryStatus = '/sol-api/apply/queryStatus'; // 获取上笔订单信息
export const getUserInfoDetail = '/sol-api/user/getUserInfoDetail'; // 获取用户信息详情
export const userBankCard = '/sol-api/user/bank/card'; // 获取用户银行卡信息
export const session = '/sol-api/user/login'; // 登录
export const validationCode = '/sol-api/app/getValidateCode'; // 获取验证码
export const password = '/sol-api/user/modify/pin'; // 修改密码
export const registeredUser = '/sol-api/user/registeredUser'; // 注册
export const contractList = '/sol-api/contract/list'; // 查询合同列表
export const contractDetail = '/sol-api/contract/detail'; // 查看合同详情
export const applyStep = '/sol-api/apply/step'; // 申请
export const product = '/sol-api/product/v1/getProduct'; // 获取产品
export const scheduleCalc = '/sol-api/contract/scheduleCalc'; // 获取试算
export const getRecommendUrl = '/sol-api/recommend/getRecommendUrl'; // 获取邀请链接
export const myRecommend = '/sol-api/recommend/myRecommend'; // 我的推广
export const myCoupon = '/sol-api/coupon/myCoupon'; // 我的优惠卷
export const useMyCoupon = '/sol-api/coupon/useMyCoupon'; // 使用优惠卷
export const behavior = '/sol-api/app/behavior'; // 用户行为
export const continuedLoan = '/sol-api/apply/continuedLoan'; // 是否续贷
export const loanAgreement = '/sol-api/contract/loan/agreement'; // 预览合同
export const logout = '/sol-api/user/logout'; // 退出登录
export const repayCode = '/sol-api/contract/repayCode'; // 还款码
export const supplementSubmit = '/sol-api/apply/supplementSubmit'; // 补件
export const barCode = '/sol-api/contract/barCode'; // 还款条形码
export const repayList = '/sol-api/contract/repayList'; // 还款单
export const updInfo = '/sol-api/customer/updateBankInfo'; // 更新客户信息
export const bankAccount = '/sol-api/pay/verify/bankAccount'; // 校验银行账户
export const payBarCode = '/sol-api/pay/barCode'; // 获取还款条形码
export const payClabe = '/sol-api/pay/clabe'; // 获取还款码

// 字典
export const dictionary = '/sol-api/dictionary/'; // 字典
export const EDUCATION = '/sol-api/dictionary/EDUCATION'; // 学历
export const MARITAL_STATUS = '/sol-api/dictionary/MARITAL_STATUS'; // 婚姻状况
export const DISTRICT = '/sol-api/dictionary/DISTRICT'; // 地址
export const INDUSTRY = '/sol-api/dictionary/INDUSTRY'; // 行业
export const PROFESSION = '/sol-api/dictionary/PROFESSION'; // 职务
export const MONTHLY_INCOME = '/sol-api/dictionary/MONTHLY_INCOME'; // 平均月收入
export const RELATIONSHIP = '/sol-api/dictionary/RELATIONSHIP'; // 亲属联系人
export const OTHER_RELATIONSHIP = '/sol-api/dictionary/OTHER_RELATIONSHIP'; // 其他联系人
export const BANK = '/sol-api/dictionary/BANK'; // 获取银行
export const CARD_TYPE = '/sol-api/dictionary/CARD_NO_TYPE';
export const PRIMAARYID = '/sol-api/dictionary/PRIMAARYID'; // 证件类型
export const PAY_ORG = '/sol-api/dictionary/PAY_ORG'; // 线下取款机构
export const CONVENIENT_TIME = '/sol-api/dictionary/CONVENIENT_TIME'; // 联系时间段
export const LOAN_PURPOSE = '/sol-api/dictionary/LOAN_PURPOSE'; // 贷款目的
export const RELIGION = '/sol-api/dictionary/RELIGION'; // 宗教
export const PAY_CYCLE = '/sol-api/dictionary/PAY_CYCLE'; // 发薪类型
export const PAY_CHL = '/sol-api/dictionary/PAY_CHL'; // 通道
