export default {
  projectName: 'SolCredito',
  login: 'Login',
  passwordLogin: 'Password login',
  verificationCodeLogin: 'Verification code login',
  codePlaceholder: 'Please input Verification code',
  registerTips: 'Don’t have an account ?',
  passwordTips: 'Forgot Password?',
  getVerificationCode: 'Get code',
  loginTips: 'Please login if you are regular user already',
  loginNow: 'Login Now',
  repayment: 'Repayment',
  loan: 'Loan',
  mine: 'Account',
  firstName: 'First name',
  firstNamePlaceholder: 'Legal First name (as seen on your ID)',
  firstNameError: 'First name cannot be empty',
  firstNameWarning: 'Legal First name (as seen on your ID)',
  middleName: 'Middle name',
  middleNamePlaceholder: 'Legal Middle Name (optional,if listed on ID)',
  middleNameError: 'Middle name cannot be empty',
  middleNameWarning: 'Legal Middle Name (optional,if listed on ID)',
  lastName: 'Last name',
  lastNamePlaceholder: 'Legal Last Name (as seen on your ID)',
  lastNameError: 'Last name cannot be empty',
  lastNameWarning: 'Legal Last Name (as seen on your ID)',
  example: 'Example',
  exampleTitle1: 'Unified Multi-Purpose ID',
  exampleTitle2: 'Social Security System',
  exampleTitle3: 'Driver’s License',
  exampleTips: 'Make sure above mentioned ID images are provided, otherwise application will be rejected:',
  exampleinfo1: '1. Make sure the ID document submiited is original document;',
  exampleinfo2: '2. Make sure the image taken is complete and clear;',
  exampleinfo3: '3. If image is not clear or not complete, will be rejected.',
  idcardType: 'ID card type',
  idcardTypePlaceholder: 'Please Select ID card type',
  idcardTypeError: 'ID card type cannot be empty',
  idcardNumber: 'ID card Number',
  idcardNumberPlaceholder: 'Please input ID card Number',
  idcardNumberError: 'ID card Number cannot be empty',
  idcardPhoto: 'ID Card Photo',
  holdIdPhoto: 'Hold ID photo',
  idcardFrontTip: 'Please take a clear ,colored photo',
  idcardAuthTips: 'Must provide above mentioned images, otherwise application will be rejected.',
  paymentMethod: 'Disbursement',
  bankTransfer: 'Bank Transfer',
  cashPickup: 'Cash Pickup',
  other: 'Other',
  bankAccountHolder: 'Bank account holder',
  bankAccountHolderPlaceholder: 'Please input Bank account holder',
  bankAccountHolderError: 'Bank account holder cannot be empty',
  bankName: 'Bank name',
  bankNamePlaceholder: 'Please Select Bank name',
  bankNameError: 'Bank name cannot be empty',
  bank: 'Bank',
  bankPlaceholder: 'Please Select Bank',
  bankError: 'Bank cannot be empty',
  bankAccountNo: 'Bank account no',
  bankAccountNoTips: '(Please be advised that the account number should input  here and not your card number.)',
  bankAccountNoPlaceholder: 'Please Select Bank account no',
  bankAccountNoError: 'Bank account no cannot be empty',
  imageOfCompanyId: 'Image of company ID',
  imageOfCompanyIdError: 'Please upload Image of company ID',
  institutionName: 'Institution Name',
  institutionNamePlaceholder: 'Please Select Institution Name',
  institutionNameError: 'Institution Name cannot be empty',
  disbursementHandlingFee: 'Disbursement Handling Fee',
  disbursementHandlingFeePlaceholder: 'Please input Institution Name',
  disbursementHandlingFeeError: 'Institution Name cannot be empty',
  channel: 'Channel',
  channelPlaceholder: 'Please Select Channel',
  channelError: 'Channel cannot be empty',
  email: 'Email',
  emailPlaceholder: 'Please input Email',
  emailError: 'Email cannot be empty',
  phoneNumber: 'Phone Number',
  phoneNumberPlaceholder: 'Please input Phone Number',
  phoneNumberError: 'Phone Number cannot be empty',
  confirmTitle: 'Considering your personal credit situation Pls select the Loanable amount .',
  selectTimeTips1: 'Please select your convenient time period, we will call you for review during this time period',
  selectTimeTips2: 'Please notice the call. Thank',
  selectTimePlaceholder: 'Please select your convenient time period',
  rejectedTips1: 'Application failed',
  rejectedTips2: 'You can re-apply for the loan, more accurate information will help you pass the verification faster.',
  passedTips1: 'The application passed( Loan under auditing )',
  toBeRepaid: 'To be repaid',
  toBeRepaid2: 'Prepayment helps to increase the amount and term of your next loan.',
  settled1: 'Settled',
  settled2: 'Congratulations on your chance to get a higher amount of loan. Go and apply!',
  overdue1: 'Overdue',
  overdue2: 'In order to avoid penalty interest, we suggest that you pay in advance or on time. Maintaining a good reputation will qualify you for a higher loan line through SolCredito.',
  waitMsg1: 'Your loan application is under processing. It is expected that the staff will contact you within 1-2 working days. Please keep your mobile phone unblocked.',
  passedMsg1: '* Your loan application has been approved and the loan is under releasing. It is expected to arrive in 1-2 working days;',
  passedMsg2: '* If you don\'t receive the loan payment, please contact the bank where your bank card is located to check. For other questions, please contact customer service: ',
  dueDate: 'Due date',
  amountDue: 'Amount due',
  orderHistory: 'Historical orders',
  bankCard: 'Bank card',
  aboutUs: 'About us',
  help: 'Help',
  noInviteFriends: 'I haven\'t invited friends yet',
  inviteFriendsImmediately: 'Invite friends now',
  noBankCard: 'No bank card',
  addBankCard: 'Add bank card',
  bankInfo: 'Bank info',
  deductionSequence: 'Deduction sequence',
  checkTheBill: 'Check the bill',
  unbind: 'unbind',
  deductionSequenceTips: 'Deductions will be made in the following order',
  longPressDrag: 'Long press drag',
  warmTips: 'Warm tips',
  unbindTips: 'Are you sure you want to unbind now?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  bvn: 'BVN',
  bvnPlaceholder: 'Please input BVN',
  bvnError: 'BVN cannot be empty',
  submit: 'Submit',
  activityRulesList1: '1.Both old and new customers can participate in this activity.',
  activityRulesList2: '2.The bonus is limited, first come first served.',
  activityRulesList3: '3.If you invite a good friend successfully, you will get 20 Peso. (invited friends will be rewarded after successful loan)',
  activityRulesList4: '4.The reward is paid directly to your SolCredito account.',
  activityRulesList5: '5.You can view the reward income in "coupons".',
  activityRulesList6: '6.SolCredito\'s interpretation rights are ultimately vested in SolCredito.',
  exclusiveInvitationQrCode: 'Exclusive invitation QR code',
  invitationAddress: 'Exclusive invitation address',
  inviteFriendsStep: 'Get rewards in three simple steps',
  inviteFriendsSteptips1: 'Invite friends to sign up',
  inviteFriendsSteptips2: 'Friends loan success',
  inviteFriendsSteptips3: 'Get cash voucher',
  activityRules: 'Activity rules',
  qrCodeTitle: 'Cash reward invitation',
  qrCodeTips: 'For each valid user invited, after the successful loan, the invitee can get a cash reward of 1000 Peso (valid user refers to the real user who has never registered for real name authentication)',
  referenceNo: 'Reference No.(Lifetime ID)',
  repaymentMethod: 'Repayment method',
  repaymentMethodInfo1: 'You can settle the loan in any branches of the following FASTPAY Payment Partners:',
  repaymentMethodInfo2: 'Settle your Account on or before 7pm of your DUE DATE,After repayment, Your repayment record will be upgraded within 1-2 working days.',
  paymentPartners1: '7-Eleven (FASTPAY)',
  paymentPartners2: 'Bayad Center',
  paymentPartners3: 'SM Department Store and Supermarket, SM Payment Counters and Savemore',
  paymentPartners4: 'Robinsons Department Store and Supermarket',
  paymentPartners5: 'LBC',
  paymentPartners6: 'Cebuana Lhuillier',
  otherHelp: 'Other help',
  step: 'Step',
  cebuanaLhuillierStepsTitle1: 'Paying via 7-Eleven c/o FASTPAY.',
  cebuanaLhuillierSteps1List1: 'Use the 7-Eleven Cliqq kios.',
  cebuanaLhuillierSteps1List2: 'Bills Payment>MORE BILLERS>Loans>FASTPAY>Enter the following required details.',
  cebuanaLhuillierSteps1List3: 'Go to cashier with slip for payment and keep your receipt.',
  cebuanaLhuillierStepsTitle2: 'Paying via Bayad Center, LBC, Cebuana Lhuillier, SM Payment Counters or Robinson’s Payment Center.',
  cebuanaLhuillierSteps2List1: 'Get a payment form and write FASTPAY as Receiver/Biller Name.',
  cebuanaLhuillierSteps2List2: 'Fill up the form provided from payment centers including reference No.(Lifetime ID) and Amount.',
  cebuanaLhuillierSteps2List3: 'Go to cashier for payment and keep your receipt.',
  loanTerm: 'Term of loan',
  day: 'Days',
  loanDetails: 'Loan details',
  customerName: 'Customer name',
  contractNo: 'Contract No',
  loanDate: 'Loan date',
  repaymentTips: 'Please repay in time, if not, you will pay high penalty interest!',
  couponPopupTips: 'Are you sure you want to exchange the coupon now?',
  toBeExchanged: 'To be exchanged',
  exchanged: 'Converted',
  expired: 'Expired',
  noToBeExchangedTips: 'No coupons',
  noExchangedTips: 'No converted coupons',
  noExpiredTips: 'No expired coupons',
  inviteFriendsNow: 'Invite friends now',
  activityTime: 'Activity time',
  activityTimeTips: '18.05.2021——31.12.2021',
  badgeExampleTips: 'Please make sure that the company name, name and your photo are within the shooting range, thank you!',
  shootVideo: 'Quay video',
  shootVideoTips: 'Please hold your ID card to take a video and read the following dialogue',
  shootVideoValue: 'I am {name} and I am applying {amount} Peso from {org} loan, I have understood the policy of {org} loan. I agree to abide by the policy and repay on time',
  register: 'Register',
  phone: 'Mobile Number',
  logOut: 'Logout',
  personalInfo: 'Personal Information',
  workInfo: 'Employment Details',
  idcardauth: 'ID card photo',
  relationshipauth: 'Relative’s details',
  copy: 'copy',
  male: 'male',
  female: 'female',
  gender: 'Gender',
  birthday: 'Date of birth',
  birthdayError: 'Please Select Date of birth',
  birthdayPlaceholder: 'Date of birth cannot be empty',
  degree: 'Education Level',
  degreePlaceholder: 'Please Select Education Level',
  degreeError: 'Education Level cannot be empty',
  addressSelect1: 'Province',
  addressSelect2: 'City',
  addressPlaceholder: "Please select an address",
  addressError: 'Address cannot be empty',
  houseNo: 'Detail address',
  houseNoPlaceholder: "Please input Detail address",
  houseNoError: 'Please input Detail address',
  enterPassword: 'Enter Password',
  password: 'Set Password',
  passwordPlaceholder: 'Please enter a 6-16 digit password',
  passwordWarning: 'Please enter a 6-16 digit password',
  passwordError: 'Password cannot be empty',
  phonePlaceholder: 'Please input your mobile number',
  phoneWarning: 'Please enter the correct mobile phone number',
  phoneError: 'Mobile number cannot be empty',
  validateCode: 'Verification code',
  validateCodeWarning: 'Please input Verification code',
  validateCodeError: 'Verification code cannot be empty',
  youCanTry: 'You can also try to',
  getIvrCode: 'get the voice verification code',
  create: 'CREATE',
  back: 'Back',
  confirmPassword: 'Confirm Password',
  confirmPasswordPlaceholder: 'Please input password again',
  applyImmediately: 'Get credit now',
  clickApplyAgree: 'I have read and agree to the',
  serviceAgreement: 'Service Agreement',
  marriage: 'Marital status',
  marriagePlaceholder: 'Please choose marital status',
  marriageError: 'Marital status cannot be empty',
  nextStep: 'Continue',
  loanPurpose: 'Loan_purpose',
  religion: 'Religion',
  loanPurposePlaceholder: 'Please select the purpose of the loan',
  loanPurposeError: 'The purpose of the loan cannot be empty.',
  religionPlaceholder: 'Please choose religion',
  religionError: 'Religion cannot be empty.',
  backupMobileNumber: 'Backup Mobile Number',
  barangay: 'Barangay',
  barangayPlaceholder: 'Please input Barangay',
  barangayError: 'Barangay cannot be empty',
  industry: 'Industry',
  industryPlaceholder: "Please Select Industry",
  industryError: 'Industry cannot be empty',
  occupationType: 'Job type',
  occupationTypePlaceholder: 'Please Select Job type',
  occupationTypeError: 'Job type cannot be empty',
  averageMonthlyIncome: 'Average monthly income',
  averageMonthlyIncomePlaceholder: "Please input Average monthly income",
  averageMonthlyIncomeError: 'Average monthly income cannot be empty',
  paydayType: 'Payday type',
  paydayTypePlaceholder: "Please Select Payday type",
  paydayTypeError: 'Payday type cannot be empty',
  payday: 'next payday',
  paydayPlaceholder: "Please Select next payday",
  paydayError: 'next payday cannot be empty',
  companyName: 'Company name',
  companyNamePlaceholder: "Please input Company name",
  companyNameError: "Company name cannot be empty",
  workPhone: 'Company phone',
  workPhonePlaceholder: 'Please input Company phone',
  workPhoneError: 'Company phone cannot be empty',
  companyAddressSelect1: 'Company state',
  companyAddressSelect2: 'Company city',
  companyAddressPlaceholder: "Please select an address",
  companyAddressError: 'Address cannot be empty',
  detailedCompanyAddress: 'Company address',
  detailedCompanyAddressPlaceholder: "Please enter the company address",
  detailedCompanyAddressError: 'Company address cannot be empty',
  companyBarangay: 'Company_barangay',
  companyBarangayPlaceholder: "Please input Company_barangay",
  companyBarangayError: 'Company_barangay cannot be empty',
  relativeContact: 'Immediate family member’s detail',
  relationship: 'Relationship',
  relationshipPlaceholder: 'Please Select Relationship',
  relationshipError: 'Relationship cannot be empty',
  hisOrHerMoblieNumber: 'His/Her Moblie Number',
  hisOrHerMoblieNumberPlaceholder: 'Please input Moblie Number',
  hisOrHerMoblieNumberError: 'Moblie Number cannot be empty',
  hisOrHerName: 'His/Her Name',
  hisOrHerNamePlaceholder: 'Please enter a name',
  hisOrHerNameError: 'Name cannot be empty',
  otherContact: 'Other contacts',
  idcardExampleList1: 'Standard',
  idcardExampleList2: 'Frame missing',
  idcardExampleList3: 'Focus blur',
  idcardExampleList4: 'Photocopy photo',
  uploadExampl: 'Upload Exampl',
  idcardFrontError: 'Please upload a photo of the face of your ID card.',
  handheldIdcardError: 'Please upload a photo of holding your ID card.',
  name: 'Name',
  namePlaceholder: 'Please enter a name',
  nameError: 'Name cannot be empty',
  loanAmount: 'Loan Amount',
  withdrawalAmount: 'Withdrawal amount',
  repaymentDate: 'Repayment date',
  repayableAmount: 'Repayable amount',
  loanTotalAmt: 'Total amount payable',
  realTotalAmt: 'Total amount repaid',
  genderError: 'Please select gender',
  copySuccess: 'Copy successfully!',
  uploadedSuccess: 'Uploaded successfully!',
  moneyMaxTips: 'Your current maximum loan amount',
  daysMaxTips: 'Your current maximum borrowing period',
  validUntil: 'Valid until',
  exchangeImmediately: 'Exchange immediately',
  contractStatus: 'Application status',
  amount: 'Amount',
  bankCardInfo: 'Bank card',
  couponPopupTitle: 'Warm tips',
  repaymentPeriod: 'Term of loan',
  prompt: 'Prompt',
  applicationDate: 'Date of application',
  inviteFriends: 'Invite friends',
  discountCoupon: 'Coupon',
  myPromotion: 'My promotion',
  myPromotionTips: 'Time to be a new user：',
  settleMsg1: '*The reapplication quota of closed customers is higher and the review is faster.！',
  overdueMsg1: '*Your loan is overdue, the overdue loan will affect your credit history, and you will not be able to borrow from other institutions in the future. Please repay the loan as soon as possible.！',
  waitTips1: 'Loan information (Under verification)',
  waitTips2: 'Refresh the interface to view the loan status',
  comfirmPasswordWarning: 'The two passwords are inconsistent.',
  comfirmPasswordError: 'Please input password',
  getSmsTips: 'The verification code may take a long time. Please wait patiently.',
  modifyImmediately: 'Modify immediately',
  reapply: 'Apply again',
  telephone: 'Customer service number',
  maximumLoanableAmount: 'Maximum Loanable Amount',
  repaymentTerm: 'Repayment Term',
  upTo: 'Up to',
  interestRate: 'Interest Rate',
  perDay: 'per Day',
  serviceCharge: 'Service Charge',
  interest: 'Interest',
  paymentAmount: 'Payment Amount',
  loanExampleTips: 'Reminder: The calculated repayment above is only for reference.',
  loanConditions: 'Loan conditions',
  loanConditions1Title: 'Age',
  loanConditions1Value: '22-55',
  loanConditions2Title: 'Job type',
  loanConditions2Value: 'People with a steady income',
  loanConditions3Title: 'ID',
  loanConditions3Value: 'Valid ID card',
  loanConditions4Title: 'Bank card',
  loanConditions4Value: 'Local valid bank card',
  youCanBorrow: 'You can borrow',
  readViewTips1: 'I have finished reading',
  readViewTips2: 'Please go through the whole Service Agreement.',
  readViewTips3: 'Please tick to confirm and continue your application.',
  loanAgreement: 'Loan Agreement',
  facebook: 'Facebook',
  facebookPlaceholder: 'Please input Facebook',
  barCodeTitle: 'Please go to any of the 7-Eleven stores for your payment',
  barCodeTips: 'Please show this to the cashier for payment',
  barCodeText: 'Cliqq Payment Code',
  remainingAmountDue: "Remaining amount due",
  applicationRepayment: "This application for repayment",
  amountPlaceholder: 'Please enter the amount',
  barCodePageTitle: 'Loan Repayment',
  clickRefresh: 'Click Refresh',
  // bankAccountHolderPlaceholder: 'Please input Bank account holder',
  // bankAccountHolderError: 'Bank account holder cannot be empty',
  // bankName: 'Bank name',
  // bankNamePlaceholder: 'Please Select Bank name',
  // bankNameError: 'Bank name cannot be empty',

  supplementaryTips1: 'Application failed',
  supplementaryTips2: 'Your identity information is incorrect',
  supplementaryTips3: 'Please complete the supplement within one working day, otherwise the application will be cancelled!',
  supplementaryInformation: 'additional materials',
  supplementaryInformationTips: 'After review, the bank card information and ID photo you filled in do not meet the requirements. Please re-enter the bank card information you applied for with your own ID and upload a clear and valid ID photo. After you submit again successfully, we will review your payment information in time, thank you!',
  supplementaryImage: 'Supplementary documents',
  supplementaryImageTips: 'After review, the ID photo you uploaded does not meet the requirements, please re-upload a clear and valid ID photo. After the upload is successful, we will review your identity information in time, thank you!',
  supplementaryBankCard: 'Supplementary bank card',
  supplementaryBankCardTips: 'After review, the bank card information you filled in is abnormal. Please re-enter the bank card information that you applied for with your own credentials. After you submit again successfully, we will review your payment information in time, thank you!',
  bankCardNumber: 'Bank card number',
  bankCardNumberPlaceholder: 'Please enter the bank card number',
  bankCardNumberError: 'Bank card number cannot be empty',
  bankAccountNumber: 'Bank Account',
  bankAccountNumberPlaceholder: 'Please enter bank account number',
  bankAccountNumberError: 'Bank account cannot be empty',

  homeLoanTips1: 'How much can I borrow?',
  homeLoanTips2: 'How much should I pay back?',
  homeLoanTips3: 'How much did I borrow last time?',
  loanInfo: "Loan arrived so fast ! !",
  get: "get",
  succesfully: "succesfully",
  loanTips: "Maintaining a good repayment behavior will help you to increase your loan amount. 96% of users loan amount haveincreased subsequently.",
  bankInfoTips: "Tip: Collections and repayments may be subject to banking",
  orderConfirmation: "Order confirmation",
  transferAmount: "Transfer amount",
  fee: "Fee",
  repaymentAmount: "Repayment amount",
  repaymentLoanMsg1: "We suggest that you repay the loan immediately on this page, so as not to affect your next loan application.",
  repaymentLoanMsg2: "We need a few minutes to process your loan repayment application，please wait patiently. If you cannot receive the verification code, please resend the verification code. Thanks for your cooperation.",
  tasaTramitacion: "Tasa de tramitacion",
  notHaveLoan: "You do not have a loan currently,please get a loan first.",
  loanRecord: "Loan record",
  filingDate: "Filing date",
  paymentDate: "Payment date",
  stage: "Stage",
  clickModifyBankCard: "Click here to modify bank card",
  modifyBankInfo: "Modify bank info",
  modify: "Modify",
  bankInfoPopupTips: "If account details are not filled in correctly, you will not be able to obtain a loan.",
  confirmBankCardInfo: "Confirm bank card details",
  oldBankAccount: "Old Bank Account",
  newBankAccount: "New Bank Account",
  getIvrHint: "Receive code by call",
  getCodeHint: "Re-acquire Validate code",
  codeInfo: "Cant't receive the Validate code?You can also check your Mobile phone signal or try the following ways.",
  getCodeTitle: "Please typ the Validate code sent to",
  cardType: "Card Type",
  cardTypePlaceholder: "Please select card type",
  verifyMarkTips: "Name and bank account number do not match",
  or: "Or",
  enterOtp: "Enter OTP",
  enterOtpTips: "An 4 digit code has been sent to",
  sendCodeTips: "Can't get the CAPTCHA? Check your cell phone signal or try the following methods.",
  sendIvrCode: "Get voice verification code",
  sendSmsCode: "Get SMS verification code",
  steps_title: 'Easy steps to get and pay for a loan:',
  steps_1_title: 'Convenient operation',
  steps_1_content: 'Fill up information within 5 mins',
  steps_2_title: 'Fast Approval',
  steps_2_content: 'Verification finished within 2 hours',
  steps_3_title: 'Safe to get disbursement',
  steps_3_content: 'Disbursement within 24 hours'
}
