export default {
  projectName: 'SolCredito',
  login: 'Inicio de sesión',
  passwordLogin: 'Inicio de sesión con contraseña',
  verificationCodeLogin: 'Acceder co código de verificación',
  codePlaceholder: 'Por favor, introduzca el código de verificación',
  registerTips: 'No tienes una cuenta',
  passwordTips: 'Olvida la contraseña.',
  getVerificationCode: 'Obtener código',
  loginTips: 'Por favor, inicie sesión si ya es usuario habitual',
  loginNow: 'Inicie sesión ahora',
  repayment: 'Reembolso',
  loan: 'Préstamo',
  mine: 'Cuenta',
  firstName: 'Nombre',
  firstNamePlaceholder: 'Nombre legal (tal y como aparece en su DNI)',
  firstNameError: 'El nombre no puede estar vacío',
  firstNameWarning: 'Nombre legal (tal y como aparece en su DNI)',
  middleName: 'Segundo nombre',
  middleNamePlaceholder: 'Segundo nombre legal (opcional, si aparece en el DNI)',
  middleNameError: 'El segundo nombre no puede estar vacío',
  middleNameWarning: 'Segundo nombre legal (opcional, si aparece en el DNI)',
  lastName: 'Apellido',
  lastNamePlaceholder: 'Apellido legal (tal y como aparece en el DNI)',
  lastNameError: 'El apellido no puede estar vacío',
  lastNameWarning: 'Apellido legal (tal y como aparece en su DNI)',
  example: 'Ejemplo:',
  exampleTitle1: 'DNI polivalente unificado',
  exampleTitle2: 'Sistema de la Seguridad Social',
  exampleTitle3: 'Licencia de conducir',
  exampleTips: 'Asegúrese de que las imágenes de los documentos de identidad mencionados anteriormente se proporcionan, de lo contrario la solicitud será rechazada:',
  exampleinfo1: '1. Asegúrese de que el documento de identidad submitido es un documento original;',
  exampleinfo2: '2. Asegúrese de que la imagen tomada es completa y clara;',
  exampleinfo3: '3. Si la imagen no es clara o no está completa, será rechazada.',
  idcardType: 'Tipo de documento de identidad',
  idcardTypePlaceholder: 'Seleccione el tipo de tarjeta de identificación',
  idcardTypeError: 'El tipo de documento de identidad no puede estar vacío',
  idcardNumber: 'Número de tarjeta de identificación',
  idcardNumberPlaceholder: 'Por favor, introduzca el número de la tarjeta de identificación',
  idcardNumberError: 'El número del documento de identidad no puede estar vacío',
  idcardPhoto: 'Foto del DNI',
  holdIdPhoto: 'Mantenga la foto del DNI',
  idcardFrontTip: 'Por favor, tome una foto clara y en color',
  idcardAuthTips: 'Debe proporcionar las imágenes mencionadas anteriormente, de lo contrario la solicitud será rechazada.',
  paymentMethod: 'Desembolso',
  bankTransfer: 'Transferencia bancaria',
  cashPickup: 'Pago en ventanilla',
  other: 'Otros',
  bankAccountHolder: 'Titular de la cuenta bancaria',
  bankAccountHolderPlaceholder: 'Por favor, introduzca el titular de la cuenta bancaria',
  bankAccountHolderError: 'El titular de la cuenta bancaria no puede estar vacío',
  bankName: 'Nombre del banco',
  bankNamePlaceholder: 'Por favor, seleccione el nombre del banco',
  bankNameError: 'El nombre del banco no puede estar vacío',
  bank: 'Banco',
  bankPlaceholder: 'Por favor, seleccione el banco',
  bankError: 'El banco no puede estar vacío',
  bankAccountNo: 'Número de cuenta bancaria',
  bankAccountNoTips: '(Por favor, tenga en cuenta que el número de cuenta debe introducirse aquí y no su número de tarjeta)',
  bankAccountNoPlaceholder: 'Por favor, seleccione el número de cuenta bancaria',
  bankAccountNoError: 'El número de cuenta bancaria no puede estar vacío',
  imageOfCompanyId: 'Imagen de identificación de la empresa',
  imageOfCompanyIdError: 'Por favor, cargue la imagen de la identificación de la empresa',
  institutionName: 'Nombre de la institución',
  institutionNamePlaceholder: 'Por favor, seleccione el nombre de la institución',
  institutionNameError: 'El nombre de la institución no puede estar vacío',
  disbursementHandlingFee: 'Tasa de tramitación de desembolsos',
  disbursementHandlingFeePlaceholder: 'Por favor, introduzca el nombre de la institución',
  disbursementHandlingFeeError: 'El nombre de la institución no puede estar vacío',
  channel: 'Canal',
  channelPlaceholder: 'Por favor, seleccione el canal',
  channelError: 'El canal no puede estar vacío',
  email: 'Correo electrónico',
  emailPlaceholder: 'Por favor, introduzca la dirección de correo electrónico',
  emailError: 'El correo electrónico no puede estar vacío',
  phoneNumber: 'Número de teléfono',
  phoneNumberPlaceholder: 'Por favor, introduzca el número de teléfono',
  phoneNumberError: 'El número de teléfono no puede estar vacío',
  confirmTitle: 'Teniendo en cuenta su situación crediticia personal, por favor seleccione la cantidad a prestar.',
  selectTimeTips1: 'Por favor, seleccione su período de tiempo conveniente, le llamaremos para la revisión durante este período de tiempo',
  selectTimeTips2: 'Por favor, tenga en cuenta la llamada. Gracias',
  selectTimePlaceholder: 'Por favor, seleccione su período de tiempo conveniente',
  rejectedTips1: 'Solicitud fallida',
  rejectedTips2: 'Puede volver a solicitar el préstamo, una información más precisa le ayudará a pasar la verificación más rápidamente.',
  passedTips1: 'La solicitud ha sido aprobada ( Préstamo en proceso de verificación )',
  toBeRepaid: 'A reembolsar',
  toBeRepaid2: 'El prepago ayuda a aumentar el importe y el plazo de su próximo préstamo.',
  settled1: 'Establecido',
  settled2: 'Enhorabuena por la posibilidad de obtener un préstamo de mayor cuantía. ¡Vaya y aplique!',
  overdue1: 'Atrasado',
  overdue2: 'Para evitar los intereses de penalización, le sugerimos que pague por adelantado o a tiempo. Mantener una buena reputación le permitirá obtener una línea de préstamo más alta a través de SolCredito.',
  waitMsg1: 'Su solicitud de préstamo está en proceso. Se espera que el personal se ponga en contacto con usted en un plazo de 1 a 2 días laborables. Por favor, mantenga su teléfono móvil desbloqueado.',
  passedMsg1: '* Su solicitud de préstamo ha sido aprobada y el préstamo está siendo liberado. Se espera que llegue en 1-2 días laborables;',
  passedMsg2: '* Si no recibe el pago del préstamo, póngase en contacto con el banco donde se encuentra su tarjeta bancaria para comprobarlo. Para otras cuestiones, póngase en contacto con el servicio de atención al cliente: ',
  dueDate: 'Fecha de vencimiento',
  amountDue: 'Importe a pagar',
  orderHistory: 'Historial de pedidos',
  bankCard: 'Tarjeta bancaria',
  aboutUs: 'Sobre nosotros',
  help: 'Ayuda',
  noInviteFriends: 'Todavía no he invitado a mis amigos',
  inviteFriendsImmediately: 'Invitar amigos ahora',
  noBankCard: 'No hay tarjeta bancaria',
  addBankCard: 'Añadir tarjeta bancaria',
  bankInfo: 'Datos bancarios',
  deductionSequence: 'Secuencia de deducción',
  checkTheBill: 'Comprobar la factura',
  unbind: 'desvincular',
  deductionSequenceTips: 'Las deducciones se harán en el siguiente orden',
  longPressDrag: 'Arrastre con pulsación larga',
  warmTips: 'Consejos de calor',
  unbindTips: 'Seguro que quiere desvincular ahora',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  bvn: 'BVN',
  bvnPlaceholder: 'Por favor, introduzca el BVN',
  bvnError: 'El BVN no puede estar vacío',
  submit: 'Submission',
  activityRulesList1: '1.Tanto los clientes antiguos como los nuevos pueden participar en esta actividad.',
  activityRulesList2: '2.La bonificación es limitada, por orden de llegada.',
  activityRulesList3: '3.Si invitas a un buen amigo con éxito, obtendrás 20 pesos. (los amigos invitados serán recompensados después de un préstamo exitoso)',
  activityRulesList4: '4.La recompensa se paga directamente a su cuenta de SolCredito.',
  activityRulesList5: '5.Puedes ver el ingreso de la recompensa en "cupones".',
  activityRulesList6: '6.Los derechos de interpretación de SolCredito son, en última instancia, de SolCredito.',
  exclusiveInvitationQrCode: 'Código QR de invitación exclusiva',
  invitationAddress: 'Dirección de invitación exclusiva',
  inviteFriendsStep: 'Obtenga recompensas en tres sencillos pasos',
  inviteFriendsSteptips1: 'Invite a sus amigos a inscribirse',
  inviteFriendsSteptips2: 'Éxito del préstamo a los amigos',
  inviteFriendsSteptips3: 'Obtenga un bono en efectivo',
  activityRules: 'Reglas de la actividad',
  qrCodeTitle: 'Invitación a la recompensa en efectivo',
  qrCodeTips: 'Por cada usuario válido invitado, después del préstamo exitoso, el invitado puede obtener una recompensa en efectivo de 1000 pesos (usuario válido se refiere al usuario real que nunca se ha registrado para la autenticación del nombre real)',
  referenceNo: 'Número de referencia (ID de vida)',
  repaymentMethod: 'Método de reembolso',
  repaymentMethodInfo1: 'Puede liquidar el préstamo en cualquier sucursal de los siguientes socios de pago FASTPAY:',
  repaymentMethodInfo2: 'Liquide su cuenta en o antes de las 7 de la tarde de su FECHA DE VENCIMIENTO, Después del reembolso, su registro de reembolso se actualizará dentro de 1-2 días hábiles.',
  paymentPartners1: '7-Eleven (FASTPAY)',
  paymentPartners2: 'Centro Bayad',
  paymentPartners3: 'Grandes almacenes y supermercados SM, mostradores de pago SM y Savemore',
  paymentPartners4: 'Grandes almacenes y supermercados Robinsons',
  paymentPartners5: 'LBC',
  paymentPartners6: 'Cebuana Lhuillier',
  otherHelp: 'Otras ayudas',
  step: 'Paso',
  cebuanaLhuillierStepsTitle1: 'Pagar a través de 7-Eleven c/o FASTPAY.',
  cebuanaLhuillierSteps1List1: 'Utilice los quioscos Cliqq de 7-Eleven.',
  cebuanaLhuillierSteps1List2: 'Pago de facturas>Más facturas>Préstamos>FASTPAY>Ingrese los siguientes datos requeridos.',
  cebuanaLhuillierSteps1List3: 'Diríjase al cajero con el comprobante de pago y guarde el recibo.',
  cebuanaLhuillierStepsTitle2: 'Pagar a través de Bayad Center, LBC, Cebuana Lhuillier, SM Payment Counters o Robinson\'s Payment Center.',
  cebuanaLhuillierSteps2List1: 'Obtenga un formulario de pago y escriba FASTPAY como nombre del receptor/cobrador.',
  cebuanaLhuillierSteps2List2: 'Rellene el formulario proporcionado por los centros de pago incluyendo el número de referencia (Lifetime ID) y el importe.',
  cebuanaLhuillierSteps2List3: 'Diríjase al cajero para realizar el pago y guarde el recibo.',
  loanTerm: 'Plazo del préstamo',
  day: 'Días',
  loanDetails: 'Detalles del préstamo',
  customerName: 'Nombre del cliente',
  contractNo: 'Número de contrato',
  loanDate: 'Fecha del préstamo',
  repaymentTips: 'Por favor, devuélvelo a tiempo, si no, pagarás altos intereses de penalización.',
  couponPopupTips: 'Está seguro de que quiere cambiar el cupón ahora',
  toBeExchanged: 'A canjear',
  exchanged: 'Convertido',
  expired: 'Caducado',
  noToBeExchangedTips: 'No hay cupones',
  noExchangedTips: 'No hay cupones convertidos',
  noExpiredTips: 'No hay cupones caducados',
  inviteFriendsNow: 'Invitar a amigos ahora',
  activityTime: 'Tiempo de actividad',
  activityTimeTips: '18.05.2021——31.12.2021',
  badgeExampleTips: 'Por favor, asegúrese de que el nombre de la empresa, el nombre y su foto están dentro del rango de tiro, ¡gracias!',
  shootVideo: 'Vídeo del muelle',
  shootVideoTips: 'Por favor, sostenga su tarjeta de identificación para tomar un video y lea el siguiente diálogo',
  shootVideoValue: 'Soy {nombre} y estoy solicitando {cantidad} Peso del préstamo de {org}, he entendido la política del préstamo de {org}. Me comprometo a respetar la política y a reembolsar a tiempo',
  register: 'Registrar',
  phone: 'Número de móvil',
  logOut: 'Cierre de sesión',
  personalInfo: 'Información personal',
  workInfo: 'Datos de empleo',
  idcardauth: 'Foto del DNI',
  relationshipauth: 'Datos de los familiares',
  copy: 'copia',
  male: 'hombre',
  female: 'mujer',
  gender: 'Género',
  birthday: 'Fecha de nacimiento',
  birthdayError: 'Por favor, seleccione la fecha de nacimiento',
  birthdayPlaceholder: 'La fecha de nacimiento no puede estar vacía',
  degree: 'Nivel de educación',
  degreePlaceholder: 'Por favor, seleccione el nivel de educación',
  degreeError: 'El nivel educativo no puede estar vacío',
  addressSelect1: 'Provincia',
  addressSelect2: 'Ciudad',
  addressPlaceholder: "Por favor, seleccione una dirección",
  addressError: 'La dirección no puede estar vacía',
  houseNo: 'Dirección detallada',
  houseNoPlaceholder: "Por favor, introduzca la dirección detallada",
  houseNoError: 'Por favor, introduzca la dirección detallada',
  enterPassword: 'Introduzca la contraseña',
  password: 'Establecer contraseña',
  passwordPlaceholder: 'Por favor, introduzca una contraseña de 4 dígitos',
  passwordWarning: 'Por favor, introduzca una contraseña de 4 dígitos',
  passwordError: 'La contraseña no puede estar vacía',
  phonePlaceholder: 'Por favor, introduzca su número de móvil',
  phoneWarning: 'Por favor, introduzca el número de móvil correcto',
  phoneError: 'El número de móvil no puede estar vacío',
  validateCode: 'Código de verificación',
  validateCodeWarning: 'Por favor, introduzca el código de verificación',
  validateCodeError: 'El código de verificación no puede estar vacío',
  youCanTry: 'También puede intentar',
  getIvrCode: 'obtener el código de verificación de voz',
  create: 'CREAR',
  back: 'Volver',
  confirmPassword: 'Confirmar contraseña',
  confirmPasswordPlaceholder: 'Por favor, introduzca la contraseña de nuevo',
  applyImmediately: 'Obtener crédito ahora',
  clickApplyAgree: 'He leído y acepto el',
  serviceAgreement: 'Acuerdo de servicio',
  marriage: 'Estado civil',
  marriagePlaceholder: 'Por favor, elija el estado civil',
  marriageError: 'El estado civil no puede estar vacío',
  nextStep: 'Continuar',
  loanPurpose: 'Propósito_del_préstamo',
  religion: 'Religión',
  loanPurposePlaceholder: 'Por favor, seleccione la finalidad del préstamo',
  loanPurposeError: 'El propósito del préstamo no puede estar vacío.',
  religionPlaceholder: 'Por favor, seleccione la religión',
  religionError: 'La religión no puede estar vacía.',
  backupMobileNumber: 'Número de móvil de respaldo',
  barangay: 'Barangay',
  barangayPlaceholder: 'Por favor, introduzca Barangay',
  barangayError: 'Barangay no puede estar vacío',
  industry: 'Industria',
  industryPlaceholder: "Por favor, seleccione la industria",
  industryError: 'La industria no puede estar vacía',
  occupationType: 'Tipo de trabajo',
  occupationTypePlaceholder: 'Por favor, seleccione el tipo de trabajo',
  occupationTypeError: 'El tipo de trabajo no puede estar vacío',
  averageMonthlyIncome: 'Ingresos medios mensuales',
  averageMonthlyIncomePlaceholder: "Por favor, introduzca la media de ingresos mensuales",
  averageMonthlyIncomeError: 'Los ingresos medios mensuales no pueden estar vacíos',
  paydayType: 'Tipo de pago',
  paydayTypePlaceholder: "Por favor, seleccione el tipo de pago",
  paydayTypeError: 'El tipo de pago no puede estar vacío',
  payday: 'Próximo día de pago',
  paydayPlaceholder: "Por favor, seleccione el próximo día de pago",
  paydayError: 'El próximo día de pago no puede estar vacío',
  companyName: 'Nombre de la empresa',
  companyNamePlaceholder: "Por favor, introduzca el nombre de la empresa",
  companyNameError: "El nombre de la empresa no puede estar vacío",
  workPhone: 'Teléfono de la empresa',
  workPhonePlaceholder: 'Por favor, introduzca el teléfono de la empresa',
  workPhoneError: 'El teléfono de la empresa no puede estar vacío',
  companyAddressSelect1: 'Estado de la empresa',
  companyAddressSelect2: 'Ciudad de la empresa',
  companyAddressPlaceholder: "Por favor, seleccione una dirección",
  companyAddressError: 'La dirección no puede estar vacía',
  detailedCompanyAddress: 'Dirección de la empresa',
  detailedCompanyAddressPlaceholder: "Por favor, introduzca la dirección de la empresa",
  detailedCompanyAddressError: 'La dirección de la empresa no puede estar vacía',
  companyBarangay: 'Barangay_de_la_empresa',
  companyBarangayPlaceholder: "Por favor, introduzca Company_barangay",
  companyBarangayError: 'Company_barangay no puede estar vacío',
  relativeContact: 'Datos del familiar directo',
  relationship: 'Relación',
  relationshipPlaceholder: 'Por favor, seleccione la relación',
  relationshipError: 'La relación no puede estar vacía',
  hisOrHerMoblieNumber: 'Su número de móvil',
  hisOrHerMoblieNumberPlaceholder: 'Por favor, introduzca el número de móvil',
  hisOrHerMoblieNumberError: 'El número de móvil no puede estar vacío',
  hisOrHerName: 'Nombre de él/ella',
  hisOrHerNamePlaceholder: 'Por favor, introduzca un nombre',
  hisOrHerNameError: 'El nombre no puede estar vacío',
  otherContact: 'Otros contactos',
  idcardExampleList1: 'Estándar',
  idcardExampleList2: 'Falta de marco',
  idcardExampleList3: 'Enfoque borroso',
  idcardExampleList4: 'Fotocopia de la foto',
  uploadExampl: 'Cargar examen',
  idcardFrontError: 'Por favor, suba una foto de la cara de su documento de identidad.',
  handheldIdcardError: 'Por favor, suba una foto sosteniendo su documento de identidad.',
  name: 'Nombre',
  namePlaceholder: 'Por favor, introduzca un nombre',
  nameError: 'El nombre no puede estar vacío',
  loanAmount: 'Importe del préstamo',
  withdrawalAmount: 'Importe de la retirada',
  repaymentDate: 'Fecha de reembolso',
  repayableAmount: 'Importe a reembolsar',
  loanTotalAmt: 'Importe total a pagar',
  realTotalAmt: 'Importe total reembolsado',
  genderError: 'Por favor, seleccione el género',
  copySuccess: '¡Copiado con éxito!',
  uploadedSuccess: '¡Cargado con éxito!',
  moneyMaxTips: 'Su importe máximo de préstamo actual',
  daysMaxTips: 'Su período máximo de préstamo actual',
  validUntil: 'Válido hasta',
  exchangeImmediately: 'Cambiar inmediatamente',
  contractStatus: 'Estado de la solicitud',
  amount: 'Importe',
  bankCardInfo: 'Tarjeta bancaria',
  couponPopupTitle: 'Consejos de calor',
  repaymentPeriod: 'Plazo del préstamo',
  prompt: 'Pronto',
  applicationDate: 'Fecha de solicitud',
  inviteFriends: 'Invitar a amigos',
  discountCoupon: 'Cupón',
  myPromotion: 'Mi promoción',
  myPromotionTips: 'Hora de ser un nuevo usuario：',
  settleMsg1: '*La cuota de reaplicación de los clientes cerrados es mayor y la revisión es más rápida.！',
  overdueMsg1: '*Su préstamo está vencido, el préstamo vencido afectará a su historial de crédito, y no podrá pedir préstamos a otras instituciones en el futuro. Por favor, devuelva el préstamo lo antes posible.！',
  waitTips1: 'Información del préstamo (En verificación)',
  waitTips2: 'Actualice la interfaz para ver el estado del préstamo',
  comfirmPasswordWarning: 'Las dos contraseñas son inconsistentes.',
  comfirmPasswordError: 'Por favor, introduzca la contraseña',
  getSmsTips: 'El código de verificación puede tardar mucho tiempo. Por favor, espere pacientemente.',
  modifyImmediately: 'Modificar inmediatamente',
  reapply: 'Vuelva a solicitarlo',
  telephone: 'Número de atención al cliente',
  maximumLoanableAmount: 'Importe máximo de préstamo',
  repaymentTerm: 'Plazo de amortización',
  upTo: 'Hasta',
  interestRate: 'Tipo de interés',
  perDay: 'por día',
  serviceCharge: 'Cargo por servicio',
  interest: 'Interés',
  paymentAmount: 'Importe del pago',
  loanExampleTips: 'Recordatorio: El reembolso calculado arriba es sólo para referencia.',
  loanConditions: 'Condiciones del préstamo',
  loanConditions1Title: 'Edad',
  loanConditions1Value: '22-55',
  loanConditions2Title: 'Tipo de empleo',
  loanConditions2Value: 'Personas con ingresos estables',
  loanConditions3Title: 'DNI',
  loanConditions3Value: 'Documento de identidad válido',
  loanConditions4Title: 'Tarjeta bancaria',
  loanConditions4Value: 'Tarjeta bancaria local válida',
  youCanBorrow: 'Puede pedir prestado',
  readViewTips1: 'He terminado de leer',
  readViewTips2: 'Por favor, lea todo el Acuerdo de Servicio.',
  readViewTips3: 'Por favor, marque para confirmar y continuar con su solicitud.',
  loanAgreement: 'Acuerdo de préstamo',
  facebook: 'Facebook',
  facebookPlaceholder: 'Por favor, introduzca Facebook',
  barCodeTitle: 'Por favor, vaya a cualquiera de las tiendas 7-Eleven para su pago',
  barCodeTips: 'Por favor, muestre esto al cajero para el pago',
  barCodeText: 'Código de pago Cliqq',
  remainingAmountDue: "Cantidad restante a pagar",
  applicationRepayment: "Esta solicitud de reembolso",
  amountPlaceholder: 'Por favor, introduzca el importe',
  barCodePageTitle: 'Reembolso del préstamo',
  clickRefresh: 'Haga clic en Actualizar',
  // bankAccountHolderPlaceholder: 'Introduzca el titular de la cuenta bancaria',
  // bankAccountHolderError: 'El titular de la cuenta bancaria no puede estar vacío',
  // bankName: 'Nombre del banco',
  // bankNamePlaceholder: 'Por favor, seleccione el nombre del banco',
  // bankNameError: 'El nombre del banco no puede estar vacío',

  supplementaryTips1: 'Solicitud fallida',
  supplementaryTips2: 'Sus datos de identidad son incorrectos',
  supplementaryTips3: 'Por favor, complete el suplemento en el plazo de un día laborable, de lo contrario la solicitud será cancelada.',
  supplementaryInformation: 'materiales adicionales',
  supplementaryInformationTips: 'Después de la revisión, la información de la tarjeta bancaria y la foto de identificación que rellenó no cumplen con los requisitos. Por favor, vuelva a introducir la información de la tarjeta bancaria que solicitó con su propio documento de identidad y cargue una foto de identificación clara y válida. Después de enviar de nuevo con éxito, revisaremos su información de pago a tiempo, ¡gracias!',
  supplementaryImage: 'Documentos complementarios',
  supplementaryImageTips: 'Después de la revisión, la foto de identificación que cargó no cumple con los requisitos, por favor, vuelva a cargar una foto de identificación clara y válida. Después de subirla con éxito, revisaremos su información de identidad a tiempo, ¡gracias!',
  supplementaryBankCard: 'Tarjeta bancaria suplementaria',
  supplementaryBankCardTips: 'Después de la revisión, la información de la tarjeta bancaria que rellenó es anormal. Por favor, vuelva a introducir la información de la tarjeta bancaria que solicitó con sus propias credenciales. Después de enviarla de nuevo con éxito, revisaremos su información de pago a tiempo, ¡gracias!',
  bankCardNumber: 'Número de tarjeta bancaria',
  bankCardNumberPlaceholder: 'Por favor, introduzca el número de la tarjeta bancaria',
  bankCardNumberError: 'El número de la tarjeta bancaria no puede estar vacío',
  bankAccountNumber: 'Cuenta bancaria',
  bankAccountNumberPlaceholder: 'Por favor, introduzca el número de la cuenta bancaria',
  bankAccountNumberError: 'La cuenta bancaria no puede estar vacía',

  homeLoanTips1: 'Cuánto Puedo Pedir Prestado',
  homeLoanTips2: 'Cuánto dinero debo devolver',
  homeLoanTips3: 'Cuánto Pedí Prestado La última Vez',
  loanInfo: "¡El Préstamo Llegó Tan Rápido!",
  get: "Obtenga",
  succesfully: "con éxito!",
  loanTips: "Mantener un buen comportamiento de pago le ayudará a aumentar el monto de su préstamo. Posteriormente se ha incrementado el 96% del monto del préstamo de los usuarios.",
  bankInfoTips: "Si la información de la cuenta se completa incorrectamente, no podrá recibir préstamos.",
  orderConfirmation: "Orden de confirmacion",
  transferAmount: "Monto de la tranferencia",
  fee: "Tarifa",
  repaymentAmount: "Monto a Pagar",
  repaymentLoanMsg1: "Le sugerimos que reembolse el préstamo inmediatamente en esta página, para no afectar su próxima solicitud de préstamo.",
  repaymentLoanMsg2: "Necesitamos unos minutos para procesar su solicitud de reembolso de préstamo, espere pacientemente. Si no puede recibir el código de verificación, vuelva a enviarlo. Gracias por su cooperación.",
  tasaTramitacion: "Tasa de tramitacion",
  notHaveLoan: "Actualmente no tiene un préstamo, por favor obtenga un préstamo primero.",
  loanRecord: "Registro de préstamo",
  filingDate: "Fecha de presentación",
  paymentDate: "Fecha de pago",
  stage: "Etapas",
  clickModifyBankCard: "haga click aqui para modificar el numero de tarjeta de banco",
  modifyBankInfo: "Modificar los datos bancarios",
  modify: "Modificar",
  bankInfoPopupTips: "Si la información de la cuenta se completa incorrectamente, no podrá recibir préstamos.",
  confirmBankCardInfo: "Confirmar los datos de la tarjeta bancaria",
  oldBankAccount: "Cuenta bancaria antigua",
  newBankAccount: "Nueva cuenta bancaria",
  getIvrHint: "Reciba El Código de Verificación Por Llamada",
  getCodeHint: "Adquirir El Código de Verificación de Nuevo",
  codeInfo: "No Puedes Recibir El Código de Verificación? Verifique Su Señal telefono O Pruebe Las Siguientes Formas.",
  getCodeTitle: "Escriba El Código de Verificación enviado",
  cardType: "Tipo de tarjeta",
  cardTypePlaceholder: "Seleccione el tipo de tarjeta",
  verifyMarkTips: "Name and bank account number do not match",
  or: "O",
  enterOtp: "Introduzca OTP",
  enterOtpTips: "Un código de 4 dígitos ha sido enviado a",
  sendCodeTips: "No se puede recibir el Código de verificación, compruebe la señal de su teléfono o pruebe lo siguiente.",
  sendIvrCode: "Obtener código de verificación de voz",
  sendSmsCode: "Obtener el código de verificación de SMS",
  steps_title: 'Pasos fáciles para obtener y pagar un préstamo',
  steps_1_title: 'Operación conveniente',
  steps_1_content: 'Complete la información en 5 minutos',
  steps_2_title: 'Aprobación rápida',
  steps_2_content: 'Verificación finalizada en 2 horas',
  steps_3_title: 'Seguro para obtener el desembolso',
  steps_3_content: 'Desembolso en 24 horas'
}
