export default {
  projectName: 'SolCredito',
  login: '登录',
  passwordLogin: '密码登录',
  verificationCodeLogin: '验证码登录',
  codePlaceholder: '请输入验证码',
  registerTips: '没有账户吗？',
  passwordTips: '忘记密码了吗？',
  getVerificationCode: '获取验证码',
  loginTips: '如果您已经是普通用户，请登录',
  loginNow: '立即登录',
  repayment: '还款',
  loan: '贷款',
  mine: '我的',
  firstName: '名字',
  firstNamePlaceholder: '合法的名字(如您的身份证所示)',
  firstNameError: '名字不能为空',
  firstNameWarning: '合法的名字(如您的身份证所示)',
  middleName: '中间名',
  middleNamePlaceholder: '合法中间名(如果在ID上列出，则可选)',
  middleNameError: '名字不能为空',
  middleNameWarning: '合法中间名(如果在ID上列出，则可选)',
  lastName: '姓',
  lastNamePlaceholder: '合法姓氏(如您的身份证所示)',
  lastNameError: '姓氏不能为空',
  lastNameWarning: '合法姓氏(如您的身份证所示)',
  example: '示例',
  exampleTitle1: 'Unified Multi-Purpose ID',
  exampleTitle2: 'Social Security System',
  exampleTitle3: 'Driver’s License',
  exampleTips: 'Make sure above mentioned ID images are provided, otherwise application will be rejected:',
  exampleinfo1: '1. Make sure the ID document submiited is original document;',
  exampleinfo2: '2. Make sure the image taken is complete and clear;',
  exampleinfo3: '3. If image is not clear or not complete, will be rejected.',
  idcardType: 'ID card type',
  idcardTypePlaceholder: 'Please Select ID card type',
  idcardTypeError: 'ID card type cannot be empty',
  idcardNumber: 'ID card Number',
  idcardNumberPlaceholder: 'Please input ID card Number',
  idcardNumberError: 'ID card Number cannot be empty',
  idcardPhoto: 'ID Card Photo',
  holdIdPhoto: 'Hold ID photo',
  idcardFrontTip: '请拍一张清晰的彩色照片',
  idcardAuthTips: '必须提供上述图片，否则申请将被拒绝。',
  paymentMethod: 'Disbursement',
  bankTransfer: '银行转移',
  cashPickup: '现金提取',
  other: '其他',
  bankAccountHolder: '银行账户持有人',
  bankAccountHolderPlaceholder: '请输入银行账户持有人',
  bankAccountHolderError: '银行账户持有人不能为空',
  bankName: 'Bank name',
  bankNamePlaceholder: 'Please Select Bank name',
  bankNameError: 'Bank name cannot be empty',
  bank: '银行',
  bankPlaceholder: '请选择银行',
  bankError: '银行不能为空',
  bankAccountNo: '银行账号',
  bankAccountNoTips: '(请注意，应在此处输入帐号，而不是您的卡号。)',
  bankAccountNoPlaceholder: '请输入银行账号',
  bankAccountNoError: '银行账号不能为空',
  imageOfCompanyId: 'Image of company ID',
  imageOfCompanyIdError: 'Please upload Image of company ID',
  institutionName: '机构名称',
  institutionNamePlaceholder: '请选择机构名称',
  institutionNameError: '机构名称不能为空',
  disbursementHandlingFee: '支付手续费',
  disbursementHandlingFeePlaceholder: '请输入机构名称',
  disbursementHandlingFeeError: '机构名称不能为空',
  channel: '通道',
  channelPlaceholder: '请选择通道',
  channelError: '通道不能为空',
  email: '电子邮件',
  emailPlaceholder: '请输入电子邮件',
  emailError: '电子邮件不能为空',
  phoneNumber: '电话号码',
  phoneNumberPlaceholder: '请输入电话号码',
  phoneNumberError: '电话号码不能为空',
  confirmTitle: '考虑到您的个人信用状况，请选择贷款额度。',
  selectTimeTips1: '请选择您方便的时间段，我们将在此时间段内打电话给您进行审核',
  selectTimeTips2: '请注意这通电话。谢谢',
  selectTimePlaceholder: '请选择您的方便时间段',
  rejectedTips1: '申请失败',
  rejectedTips2: '您可以重新申请贷款，更准确的信息将帮助您更快地通过审核。',
  passedTips1: '申请通过(放款中)',
  toBeRepaid1: '待偿还',
  toBeRepaid2: '提前还款有助于增加你下一笔贷款的金额和期限。',
  settled1: '已结清',
  settled2: '祝贺你有机会获得更高额度的贷款。去申请吧！',
  overdue1: '逾期未付',
  overdue2: '为了避免罚息，我们建议您提前或按时付款。保持良好的声誉将使您有资格通过SolCredito获得更高的贷款额度。',
  waitMsg1: '您的贷款申请正在处理中。预计工作人员将在1-2个工作日内与您联系。请保持您的手机畅通。',
  passedMsg1: '*您的借款申请已通过审核，正在放款，预计1-2个工作日会到账;',
  passedMsg2: '*如果您没有收到贷款付款，请联系您的银行卡所在银行查询，其他问题请联系客服：',
  dueDate: '还款日',
  amountDue: '到期金额',
  orderHistory: '订单历史',
  bankCard: '银行卡',
  aboutUs: '关于我们',
  help: '帮助',
  noInviteFriends: '还没有邀请好友',
  inviteFriendsImmediately: '立即邀请好友',
  noBankCard: '没有银行卡',
  addBankCard: '添加银行卡',
  bankInfo: '银行信息',
  deductionSequence: '扣款顺序',
  checkTheBill: '检查帐单',
  unbind: '解除绑定',
  deductionSequenceTips: '扣除将按以下顺序进行',
  longPressDrag: '长按拖动',
  warmTips: '温馨提示',
  unbindTips: '是否确实要立即解除绑定？',
  confirm: '确认',
  cancel: '取消',
  bvn: 'BVN',
  bvnPlaceholder: '请输入BVN',
  bvnError: 'BVN不能为空',
  submit: '提交',
  activityRulesList1: '1.新老客户均可参加本次活动。',
  activityRulesList2: '2.奖金有限，先到先得。',
  activityRulesList3: '3.如果你邀请到一个好朋友，你将得到20比索。(受邀好友贷款成功后将获得奖励)',
  activityRulesList4: '4.奖金会直接打到你的现金账户上。',
  activityRulesList5: '5.您可以在“优惠券”中查看奖励收入。',
  activityRulesList6: '6.现金的解释权最终归现金所有。',
  exclusiveInvitationQrCode: '独家邀请二维码',
  invitationAddress: '专属邀请地址',
  inviteFriendsStep: '简单三步获取奖励',
  inviteFriendsSteptips1: '邀请好友注册',
  inviteFriendsSteptips2: '好友贷款成功',
  inviteFriendsSteptips3: '领取现金券',
  activityRules: '活动规则',
  qrCodeTitle: '现金奖励邀请函',
  qrCodeTips: '每邀请一名有效用户，贷款成功后，受邀者可获得1000比索的现金奖励(有效用户是指从未注册实名认证的真实用户)',
  referenceNo: '参考号(终身ID)',
  repaymentMethod: '还款方式',
  repaymentMethodInfo1: '你可以在以下支付伙伴的任何一间分行交收贷款：',
  repaymentMethodInfo2: '在到期日晚上7点或之前结账，还款后，您的还款记录将在1-2个工作日内更新。',
  paymentPartners1: '7-Eleven (Dragonloans)',
  paymentPartners2: 'Bayad Center',
  paymentPartners3: 'SM Department Store and Supermarket, SM Payment Counters and Savemore',
  paymentPartners4: 'Robinsons Department Store and Supermarket',
  paymentPartners5: 'LBC',
  paymentPartners6: 'Cebuana Lhuillier',
  otherHelp: '其他帮助',
  step: '步骤',
  cebuanaLhuillierStepsTitle1: '通过7-Eleven c/o龙贷支付。',
  cebuanaLhuillierSteps1List1: '使用7-Eleven Cliqq Kios。',
  cebuanaLhuillierSteps1List2: '票据付款>更多开票人>贷款>龙贷>输入以下必需的详细信息。',
  cebuanaLhuillierSteps1List3: '拿着收银单去收银台付款，并保管好收据。',
  cebuanaLhuillierStepsTitle2: '通过Bayad Center、LBC、Cebuana Lhuillier、SM支付柜台或罗宾逊支付中心付款。',
  cebuanaLhuillierSteps2List1: '拿到一张付款表格，并将DRAGONPAY写为收款人/账单人的名字。',
  cebuanaLhuillierSteps2List2: '填写支付中心提供的表格，包括参考号(终身ID)和金额。',
  cebuanaLhuillierSteps2List3: '去收银台付款，并保管好收据。',
  loanTerm: '借款期限',
  day: '天',
  loanDetails: '贷款明细',
  customerName: '客户名称',
  contractNo: '合同号',
  loanDate: '贷款日期',
  repaymentTips: '请及时还款，否则将支付高额罚息！',
  couponPopupTips: '您确定现在要兑换优惠券吗？',
  toBeExchanged: '待兑换',
  exchanged: '已兑换',
  expired: '已过期',
  noToBeExchangedTips: '没有优惠券',
  noExchangedTips: '没有使用的优惠券',
  noExpiredTips: '没有过期的优惠券',
  inviteFriendsNow: '立即邀请朋友',
  activityTime: '活动时间',
  activityTimeTips: '18.05.2021——31.12.2021',
  badgeExampleTips: '请确保公司名称，名称和照片在拍摄范围内，谢谢！',
  shootVideo: 'Quay video',
  shootVideoTips: '请拿着身份证拍摄视频，阅读下面的对话',
  shootVideoValue: 'I am {name} and I am applying {amount} Peso from {org} loan, I have understood the policy of {org} loan. I agree to abide by the policy and repay on time',
  register: '注册',
  phone: '手机号',
  logOut: '退出登录',
  personalInfo: '个人信息',
  workInfo: '工作信息',
  idcardauth: '身份认证',
  relationshipauth: '联系人认证',
  copy: '复制',
  male: '男',
  female: '女',
  gender: '性别',
  birthday: '生日',
  birthdayError: '生日不能为空',
  birthdayPlaceholder: '请选择您的生日',
  degree: '学历',
  degreePlaceholder: '请选择您的学历',
  degreeError: '学历不能为空',
  addressSelect1: '省/市',
  addressSelect2: '区',
  addressPlaceholder: "请选择居住地址",
  addressError: '居住地址不能为空',
  houseNo: '详细地址',
  houseNoPlaceholder: "请输入详细地址",
  houseNoError: '请输入详细地址',
  enterPassword: '输入密码',
  password: '设置密码',
  passwordPlaceholder: '请输入6-16位数字的密码',
  passwordWarning: '请输入6-16位数字的密码',
  passwordError: '密码不能为空',
  phonePlaceholder: '请输入您的手机号',
  phoneWarning: '请输入正确的手机号',
  phoneError: '手机号不能为空',
  validateCode: '验证码',
  validateCodeWarning: '验证码格式错误',
  validateCodeError: '验证码不能为空',
  youCanTry: '您也可以试试',
  getIvrCode: '获取语音验证码',
  create: '创建',
  back: '返回',
  confirmPassword: '确认密码',
  confirmPasswordPlaceholder: '请重新输入密码',
  applyImmediately: '立即申请',
  clickApplyAgree: '我已阅读并同意',
  serviceAgreement: '《服务协议》',
  marriage: '婚姻状况',
  marriagePlaceholder: '请选择婚姻状况',
  marriageError: '婚姻状况不能为空',
  nextStep: '下一步',
  loanPurpose: '贷款目的',
  loanPurposePlaceholder: '请选择贷款目的',
  loanPurposeError: '贷款目的不能为空',
  religion: '宗教',
  religionPlaceholder: '请选择宗教',
  religionError: '宗教不能为空',
  backupMobileNumber: '备用手机号',
  barangay: 'Barangay',
  barangayPlaceholder: 'Please input Barangay',
  barangayError: 'Barangay cannot be empty',
  industry: '所属行业',
  industryPlaceholder: "请选择所属行业",
  industryError: '所属行业不能为空',
  occupationType: '职业类型',
  occupationTypePlaceholder: '请选择职业类型',
  occupationTypeError: '职业类型不能为空',
  averageMonthlyIncome: '平均月收入',
  averageMonthlyIncomePlaceholder: "请选择平均月收入",
  averageMonthlyIncomeError: '平均月收入不能为空',
  paydayType: '发薪日类型',
  paydayTypePlaceholder: "请选择发薪日类型",
  paydayTypeError: '发薪日类型不能为空',
  payday: '下次发薪日',
  paydayPlaceholder: "请选择下次发薪日",
  paydayError: '下次发薪日不能为空',
  companyName: '公司名称',
  companyNamePlaceholder: "请输入公司名称",
  companyNameError: "公司名称不能为空",
  workPhone: '工作电话',
  workPhonePlaceholder: '请输入工作电话',
  workPhoneError: '工作电话不能为空',
  companyAddressSelect1: 'Company state',
  companyAddressSelect2: 'Company city',
  companyAddressPlaceholder: "请选择地址",
  companyAddressError: '地址不能为空',
  detailedCompanyAddress: '公司地址',
  detailedCompanyAddressPlaceholder: "请输入公司地址",
  detailedCompanyAddressError: '公司地址不能为空',
  companyBarangay: 'Company_barangay',
  companyBarangayPlaceholder: "Please input Company_barangay",
  companyBarangayError: 'Company_barangay cannot be empty',
  relativeContact: '直系亲属联系人信息',
  relationship: '关系',
  relationshipPlaceholder: '请选择联系人关系',
  relationshipError: '关系不能为空',
  hisOrHerMoblieNumber: '他/她的Moblie号码',
  hisOrHerMoblieNumberPlaceholder: '请输入手机号码',
  hisOrHerMoblieNumberError: '手机号码不能为空',
  hisOrHerName: 'His/Her Name',
  hisOrHerNamePlaceholder: '请输入姓名',
  hisOrHerNameError: '姓名不能为空',
  otherContact: '其他联系人',
  idcardExampleList1: '标准',
  idcardExampleList2: '边框缺失',
  idcardExampleList3: '对焦模糊',
  idcardExampleList4: '复印件拍照',
  uploadExampl: '上传示例',
  idcardFrontError: '请上传身份证人面照片',
  handheldIdcardError: '请上传手持身份证照片',
  name: '姓名',
  namePlaceholder: '请输入姓名',
  nameError: '姓名不能为空',
  loanAmount: '借款金额',
  withdrawalAmount: '可提现金额',
  repaymentDate: '还款日期',
  repayableAmount: '应还金额',
  loanTotalAmt: '应还总额',
  realTotalAmt: '已还总额',
  genderError: '请选择性别',
  copySuccess: '复制成功!',
  uploadedSuccess: '上传成功!',
  moneyMaxTips: '您目前的最高贷款额',
  daysMaxTips: '您当前的最长借款期',
  validUntil: '有效期至',
  exchangeImmediately: '立即兑换',
  contractStatus: '合同状态',
  amount: '金额',
  applicationDate: '申请日期',
  bankCardInfo: '银行卡信息',
  couponPopupTitle: '温馨提示',
  repaymentPeriod: '还款期限',
  prompt: '提示',
  inviteFriends: '邀请好友',
  discountCoupon: '优惠卷',
  myPromotion: '我的推广',
  myPromotionTips: '成为新用户时间:',
  settleMsg1: '*已结清客户再次申请额度更高，审核更快！',
  overdueMsg1: '*您的借款已逾期，逾期借款将会影响您的信用记录,后续无法从其他机构贷款，请尽快还款！',
  waitTips1: '贷款信息(审批中)',
  waitTips2: '刷新界面查看贷款状态',
  comfirmPasswordWarning: '两次密码输入不一致',
  comfirmPasswordError: '请输入密码',
  getSmsTips: '验证码可能需要时间较长，请耐心等待',
  modifyImmediately: '立即修改',
  reapply: '重新申请',
  telephone: '客服电话',
  maximumLoanableAmount: '最高可贷款额',
  repaymentTerm: '还款期限',
  upTo: '最高可达',
  interestRate: '利率',
  perDay: 'per Day',
  serviceCharge: '服务费',
  interest: '利息',
  paymentAmount: '付款金额',
  loanExampleTips: '提醒：以上计算的还款额仅供参考。',
  loanConditions: '贷款条件',
  loanConditions1Title: '年龄',
  loanConditions1Value: '22-55',
  loanConditions2Title: '职业类型',
  loanConditions2Value: '有稳定收入的人',
  loanConditions3Title: 'ID',
  loanConditions3Value: '有效身份证',
  loanConditions4Title: '银行卡',
  loanConditions4Value: '本地有效银行卡',
  youCanBorrow: '你可以借给我',
  readViewTips1: '我已经读完了',
  readViewTips2: '请仔细阅读整个服务协议。',
  readViewTips3: '请勾选确认并继续您的申请。',
  loanAgreement: 'Loan Agreement',
  facebook: 'Facebook',
  facebookPlaceholder: '请输入Facebook',
  barCodeTitle: 'Please go to any of the 7-Eleven stores for your payment',
  barCodeTips: 'Please show this to the cashier for payment',
  barCodeText: 'Cliqq Payment Code',
  remainingAmountDue: "Remaining amount due",
  applicationRepayment: "This application for repayment",
  amountPlaceholder: 'Please enter the amount',
  barCodePageTitle: 'Loan Repayment',
  clickRefresh: 'Click Refresh',


  supplementaryTips1: '申请失败',
  supplementaryTips2: '您的身份信息填写不正确',
  supplementaryTips3: '请在一个工作日内补充完整,否则将取消申请!',
  supplementaryInformation: '补充资料',
  supplementaryInformationTips: '经审核，您填写的银行卡信息和身份证照片不符合要求，请重新输入以您本人证件申请的银行卡信息，上传清晰、有效的证件照片。再次提交成功后，我们将及时对您的支付信息进行审核，谢谢！',
  supplementaryImage: '补充证件',
  supplementaryImageTips: '经审核，您上传的身份证照片不符合要求，请重新上传清晰、有效的证件照片。上传成功后，我们将及时对您的身份信息进行审核，谢谢！',
  supplementaryBankCard: '补充银行卡',
  supplementaryBankCardTips: '经审核，您填写的银行卡信息异常，请重新输入以您本人证件申请的银行卡信息。再次提交成功后，我们将及时对您的支付信息进行审核，谢谢！',
  bankCardNumber: '银行卡号',
  bankCardNumberPlaceholder: '请输入银行卡号',
  bankCardNumberError: '银行卡号不能为空',
  bankAccountNumber: '银行账号',
  bankAccountNumberPlaceholder: '请输入银行账号',
  bankAccountNumberError: '银行账号不能为空',

  homeLoanTips1: 'How much can I borrow?',
  homeLoanTips2: 'How much should I pay back?',
  homeLoanTips3: 'How much did I borrow last time?',
  loanInfo: "Loan arrived so fast ! !",
  get: "get",
  succesfully: "succesfully",
  loanTips: "Maintaining a good repayment behavior will help you to increase your loan amount. 96% of users loan amount haveincreased subsequently.",
  bankInfoTips: "Tip: Collections and repayments may be subject to banking",
  orderConfirmation: "Order confirmation",
  transferAmount: "Transfer amount",
  fee: "Fee",
  repaymentAmount: "Repayment amount",
  repaymentLoanMsg1: "We suggest that you repay the loan immediately on this page, so as not to affect your next loan application.",
  repaymentLoanMsg2: "We need a few minutes to process your loan repayment application，please wait patiently. If you cannot receive the verification code, please resend the verification code. Thanks for your cooperation.",
  tasaTramitacion: "Tasa de tramitacion",
  notHaveLoan: "You do not have a loan currently,please get a loan first.",
  loanRecord: "Loan record",
  filingDate: "Filing date",
  paymentDate: "Payment date",
  stage: "Stage",
  clickModifyBankCard: "Click here to modify bank card",
  modifyBankInfo: "Modify bank info",
  modify: "Modify",
  bankInfoPopupTips: "If account details are not filled in correctly, you will not be able to obtain a loan.",
  confirmBankCardInfo: "Confirm bank card details",
  oldBankAccount: "Old Bank Account",
  newBankAccount: "New Bank Account",
  getIvrHint: "Receive code by call",
  getCodeHint: "Re-acquire Validate code",
  codeInfo: "Cant't receive the Validate code?You can also check your Mobile phone signal or try the following ways.",
  getCodeTitle: "Please typ the Validate code sent to",
  cardType: "Card Type",
  cardTypePlaceholder: "Please select card type",
  verifyMarkTips: "Name and bank account number do not match",
  or: "Or",
  enterOtp: "Enter OTP",
  enterOtpTips: "An 4 digit code has been sent to",
  sendCodeTips: "收不到验证码？ 检查您的手机信号或尝试以下方法。",
  sendIvrCode: "获取语音验证码",
  sendSmsCode: "获取短信验证码",
  steps_title: 'Easy steps to get and pay for a loan:',
  steps_1_title: 'Convenient operation',
  steps_1_content: 'Fill up information within 5 mins',
  steps_2_title: 'Fast Approval',
  steps_2_content: 'Verification finished within 2 hours',
  steps_3_title: 'Safe to get disbursement',
  steps_3_content: 'Disbursement within 24 hours'
}
