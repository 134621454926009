<template>
  <div class="home">
    <img class="home_logo" src="@/assets/home_logo.png" />
    <mt-header>
      <call-phone slot="right" />
    </mt-header>
    <scroll-component />
    <div class="apply">
      <div class="box">
        <img class="bar" src="@/assets/home_bg_1.png">
        <div class="val">
          <div class="title">
            {{ title }}
          </div>
          <div class="tips">
            $ {{ amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <mu-button
          full-width
          color="#E7AD5C"
          text-color="#18252A"
          @click="submit"
        >
          {{ $t("applyImmediately") }}
        </mu-button>
    </div>
    <div class="content">
      <!-- <div class="not_settle" v-if="user.notSettleCount">
        <div class="val">
          <div>
            {{ $t('loanLengthTips') }}
            <span>{{ user.notSettleCount }}</span>
          </div>
          <span class="more" @click="$router.push('/mine/repayRecord')">{{ $t('viewMore') }}</span>
        </div>
        <img src="../assets/loan_img_4.png">
      </div> -->
      <div class="steps_title">{{ $t('steps_title') }}</div>
      <div class="stpes">
        <img class="stpes_icon" src="../assets/steps_icon_1.png" />
        <div class="steps_value">
          <div>{{ $t('steps_1_title') }}</div>
          <div>{{ $t('steps_1_content') }}</div>
        </div>
      </div>
      <div class="stpes">
        <img class="stpes_icon" src="../assets/steps_icon_2.png" />
        <div class="steps_value">
          <div>{{ $t('steps_2_title') }}</div>
          <div>{{ $t('steps_2_content') }}</div>
        </div>
      </div>
      <div class="stpes">
        <img class="stpes_icon" src="../assets/steps_icon_3.png" />
        <div class="steps_value">
          <div>{{ $t('steps_3_title') }}</div>
          <div>{{ $t('steps_3_content') }}</div>
        </div>
      </div>
      <!-- <div class="flag">
        <img src="../assets/home_bg_2.png">
        <div class="val">
          <div>
            {{ $t('upTo') }}
            <span>₦ 600.000</span>
          </div>
          <span>{{ $t('loanInfo') }}</span>
          <div class="go" @click="submit">
            GO>
          </div>
        </div>
      </div> -->
    </div>

    <tab-bar />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getUserInfo, applyStep, contractDetail } from '../utils/api'
import callPhone from '@/components/callPhone.vue'
import scrollComponent from '@/components/scrollComponent'
import tabBar from '@/components/tabBar.vue'
import branch from '../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../utils/gtm.js'
export default {
  name: 'Home',
  components: {
    callPhone,
    tabBar,
    scrollComponent
  },
  data() {
    return {
      title: this.$t('homeLoanTips1'),
      amount: '????',
    }
  },
  computed: {
    ...mapState(["brand", "user"]),
    privacyUrl() {
      if (this.brand.channelInfo) {
        return this.brand.channelInfo.privacyUrl
      }
      return ''
    }
  },
  watch: {

  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P01_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P01_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_CHECKED_SERVICE_AGREEMENT"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getUser(),
      ])
      this.$indicator.close();
      this.$NProgress.done()
    },
    submit() {
      switch (this.user.applyStatus) {
        case 'EMPTY':
        case 'APPLY':
        case 'REJECTED':
        case 'CANCEL':
        case 'SETTLE':
        case 'FREEZE':
        case 'EXPIRED':
          if (this.user.continuedLoan == 'Y') {
            this.onReApply() // 续贷申请
            break
          }
          this.$router.push('/mine/orderHistory')
          // this.onApplyPre() // 首贷申请
          break
        case 'WAIT':
        case 'LOAN':
        case 'NORMAL':
        case 'OVERDUE':
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
        case 'SUPPLEMENTARY':
        case 'SUPPLEMENT_IMAGE': // 补证件
        case 'SUPPLEMENT_BANK_CARD': // 补银行卡
        case 'SUPPLEMENT_IMAGE_CARD': // 补证件 & 银行卡
          this.$router.push({path: '/step/supplementary'}) // 补充资料
          break
        default:
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
      }
    },
    // 预申请
    async onApplyPre() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('01_FIRST_LOAN_APPLY', { content_name: '01_FIRST_LOAN_APPLY' })
        }
        // Google Analytics  FIRST_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'FIRST_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }

      await this.$axios({
        method: "post",
        url: applyStep,
        data: {
          currentStep: 1,
          totalSteps: 8,
          phone: this.user.phone,
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            if ("Y" == e.body.isWhiteListFlag) {
              this.$router.push('/step/relationshipauth')
            } else {
              this.$router.push('/step/jobauth')
            }
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()

      branch.logEvent('01_FIRST_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 续贷申请
    async onReApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('11_CONTINUED_LOAN_APPLY', {
            content_name: '11_CONTINUED_LOAN_APPLY'
          })
        }
        // Google Analytics  CONTINUED_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'CONTINUED_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: {
          currentStep: 1,
          totalSteps: 8,
          continuedLoan: 'Y',
          phone: this.user.phone,
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('11_CONTINUED_LOAN_APPLY', { metadata: this.user.phone })
    },
    onServiceAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.privacyUrl)
      }
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
          switch (e.body.applyStatus) {
              case "EMPTY":
              case "APPLY":
              case "SIGN":
              case "APPROVE":
              case "LOAN":
              case "WAIT":
                this.title = this.$t('homeLoanTips1')
                break;
              case "REPAY":
                this.title = this.$t('homeLoanTips2')
                this.getDetails("REPAY");
                break;
              default:
                this.title = this.$t('homeLoanTips3')
                this.getDetails("OTHER");
                break;
          }
        }
      }).catch(() => {})
    },
    async getDetails(text) {
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      await this.$axios({
        method: 'post',
        url: contractDetail,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            if ('REPAY' == text) {
              this.amount = e.body.repayAmount
            } else {
              this.amount = e.body.applyAmount
            }
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  background-color: #18252A;
  min-height: 100vh;
  .home_logo {
    position: absolute;
    z-index: 1;
    height: 22px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .titieIcon {
    color: white;
    font-size: 26px;
  }
  .apply {
    position: relative;
    margin: 18px 25px;
    border-radius: 10px;
    .box {
      position: relative;
      overflow: hidden;
      display: flex;
      .bar {
        width: 100%;
      }
      .val {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .title {
          font-weight: bold;
          color: white;
          font-size: 14px;
          text-align: center;
          margin-top: 10%;
          font-weight: bold;
        }
        .tips {
          margin-top: 3%;
          font-size: 44px;
          text-align: center;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
  .buttons {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    /deep/ .mu-raised-button {
      border-radius: 8px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
  .content {
    position: relative;
    margin-top: 40px;
    padding-bottom: 40px;
    background: #F8F8F8;
    &::before {
      content: '';
      position: absolute;
      height: 50px;
      top: -40px;
      left: 0;
      width: 100%;
      background: url(../assets/home_bottom_bg.png);
      background-size: 100%;
    }
    .steps_title {
      font-size: 18px;
      color: #333230;
      padding: 10px;
      text-align: center;
    }
    .stpes {
      margin: 28px 28px 0 28px;
      display: flex;
      align-items: center;
      border: 1px solid #D8DEEC;
      border-radius: 5px;
      .stpes_icon {
        margin: 21px 15px;
        width: 48px;
        height: 48px;
      }
      .steps_value {
        font-size: 16px;
        color: #F24431;
        div {
          &:last-child {
            color: #7B7B7B;
            font-size: 14px;
          }
        }
      }
    }
    // <div class="stpes">
    //     <div class="stpes_icon"></div>
    //     <div class="steps_value">
    //       <div>{{ $t('steps_1_title') }}</div>
    //       <div>{{ $t('steps_1_content') }}</div>
    //     </div>
    //   </div>
  }
  .not_settle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 25px 20px 25px;
    background: white;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
    img {
      width: 100px;
      margin: 15px;
    }
    .val {
      padding: 10px;
      flex: 1;
      color: #F24431;
      div {
        margin-bottom: 10px;
      }
      div > span {
        color: #F24431;
        font-weight: 700;
      }
      .more {
        color: white;
        background: #F24431;
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
  }
  .flag {
    position: relative;
    margin: 20px 20px 20px 20px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
    img {
      width: 100%;
    }
    .val {
      position: absolute;
      padding: 10px 0px;
      text-align: right;
      right: 20px;
      font-size: 20px;
      color: #F15234;
      font-weight: 700;
      div > span {
        color: #FFD801;
      }
      > span {
        color: #869096;
        font-size: 13px;
      }
      .go {
        width: 50px;
        background-color: #FFD801;
        border-radius: 50px;
        text-align: center;
        color: white;
        font-weight: 700;
        margin-top: 10px;
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
</style>
