import Vue from 'vue'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './language/index'
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import './utils/axios'
import './utils/nprogress'
import './filters'
import '../public/font/iconfont.css'
import '../public/font/iconfont.js'
import InputFacade from 'vue-input-facade'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import AccountKit from 'vue-facebook-account-kit'
import branch from '../node_modules/branch-sdk'
import draggable from 'vuedraggable'

Vue.component('draggable', draggable)

Vue.use(MuseUI);

const options = {
  name: 'mask',
  tokens: {
    '#': { pattern: /\d/ },
    'A': { pattern: /[A-Za-z]/i },
    'T': { pattern: /[0-9A-Za-z]/i },
    'X': { pattern: /./ }
  }
}
Vue.use(InputFacade, options)
Vue.use(MintUI)
Vue.use(VueFacebookPixel)
Vue.use(AccountKit)
Vue.use(branch)

Vue.config.productionTip = false

window.Vue = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
