import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 我的
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine.vue')
  },
  // 还款
  {
    path: '/repayment',
    name: 'Repayment',
    component: () => import('../views/Repayment.vue')
  },
  // 订单历史
  {
    path: '/mine/orderHistory',
    name: 'OrderHistory',
    component: () => import('../views/mine/OrderHistory.vue')
  },
  // 还款账单
  {
    path: '/mine/repayRecord',
    name: 'RepayRecord',
    component: () => import('../views/mine/RepayRecord.vue')
  },
  // 还款信息
  {
    path: '/mine/repaymentInfo',
    name: 'RepaymentInfo',
    component: () => import('../views/mine/RepaymentInfo.vue')
  },
  // 还款码
  {
    path: '/mine/repaymentCode',
    name: 'RepaymentCode',
    component: () => import('../views/mine/RepaymentCode.vue')
  },
  // 银行卡
  {
    path: '/mine/bankCard',
    name: 'BankCard',
    component: () => import('../views/mine/bankCard/BankCard.vue')
  },
  // 添加银行卡
  {
    path: '/mine/addBankCard',
    name: 'AddBankCard',
    component: () => import('../views/mine/bankCard/AddBankCard.vue')
  },
  // 银行卡详情
  {
    path: '/mine/bankInfo',
    name: 'BankInfo',
    component: () => import('../views/mine/bankCard/BankInfo.vue')
  },
  // 银行卡扣款顺序
  {
    path: '/mine/deductionSequence',
    name: 'DeductionSequence',
    component: () => import('../views/mine/bankCard/DeductionSequence.vue')
  },
  // 邀请好友
  {
    path: '/mine/inviteFriends',
    name: 'InviteFriends',
    component: () => import('../views/mine/InviteFriends.vue')
  },
  // 邀请二维码
  {
    path: '/mine/qrCode',
    name: 'Qrcode',
    component: () => import('../views/mine/QrCode.vue')
  },
  // 优惠卷
  {
    path: '/mine/discountCoupon',
    name: 'DiscountCoupon',
    component: () => import('../views/mine/DiscountCoupon.vue')
  },
  // 我的推广
  {
    path: '/mine/myPromotion',
    name: 'MyPromotion',
    component: () => import('../views/mine/MyPromotion.vue')
  },
  // 关于我们
  {
    path: '/mine/aboutUs',
    name: 'AboutUs',
    component: () => import('../views/mine/AboutUs.vue')
  },
  // 帮助
  {
    path: '/mine/help',
    name: 'Help',
    component: () => import('../views/mine/Help.vue')
  },
  // 用户（登录 注册 修改密码）
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/user/code',
    name: 'Code',
    component: () => import('../views/user/Code.vue')
  },
  {
    path: '/user/login',
    name: 'Login',
    component: () => import('../views/user/Login.vue')
  },
  {
    path: '/user/password',
    name: 'Password',
    component: () => import('../views/user/Password.vue')
  },
  {
    path: '/user/register',
    name: 'Register',
    component: () => import('../views/user/Register.vue')
  },
  // 订单申请状态
  {
    path: '/apply/done',
    name: 'Done',
    component: () => import('../views/apply/Done.vue')
  },
  // 订单详情
  {
    path: '/apply/orderDetails',
    name: 'OrderDetails',
    component: () => import('../views/apply/OrderDetails.vue')
  },
  // 视频认证
  {
    path: '/apply/shootVideo',
    name: 'ShootVideo',
    component: () => import('../views/apply/ShootVideo.vue')
  },
  // 还款条形码
  {
    path: '/apply/barCode',
    name: 'BarCode',
    component: () => import('../views/apply/BarCode.vue')
  },
  // 补件（身份证 or 银行卡 or 身份证和银行卡）
  {
    path: '/step/supplementary',
    name: 'Supplementary',
    component: () => import('../views/step/Supplementary.vue')
  },
  // 实名身份验证
  {
    path: '/step/realnameauth',
    name: 'Realnameauth',
    component: () => import('../views/step/Realnameauth.vue')
  },
  // 工作认证
  {
    path: '/step/jobauth',
    name: 'Jobauth',
    component: () => import('../views/step/Jobauth.vue')
  },
  // 联系人认证
  {
    path: '/step/relationshipauth',
    name: 'Relationshipauth',
    component: () => import('../views/step/Relationshipauth.vue')
  },
  // 修改银行卡
  {
    path: '/step/modifyBankInfo',
    name: 'ModifyBankInfo',
    component: () => import('../views/step/ModifyBankInfo.vue')
  },
  // 身份认证
  {
    path: '/step/idcardauth',
    name: 'Idcardauth',
    component: () => import('../views/step/Idcardauth.vue')
  },
  // 付款方式
  {
    path: '/step/paymentMethod',
    name: 'PaymentMethod',
    component: () => import('../views/step/PaymentMethod.vue')
  },
  // 确认订单
  {
    path: '/step/confirm',
    name: 'Confirm',
    component: () => import('../views/step/Confirm.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (/^\/user/.test(to.path)) return next()
  if (!store.state.accessToken) return next({path: '/user'})
  next();
})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
